<template>
  <div class="app-container" :style="`background-color: ${colorStore.colors.hex}`">
    <div class="camera-outer-container">
      <div class="camera-container">
        <div
          class="video-wrapper"
          :style="{
            width: getVideoWrapperSize(aspectRatio).width + 'px',
            height: getVideoWrapperSize(aspectRatio).height + 'px'
          }"
        >
          <video
            ref="videoRef"
            autoplay
            playsinline
            :style="{
              objectFit: 'cover'
            }"
          />
        </div>
        <button
          class="take-photo-btn"
          @click="handleTakePhoto"
        >
          📸
        </button>
      </div>
    </div>

    <div class="controls-container">
      <div class="aspect-ratio-buttons">
        <button
          v-for="ratio in aspectRatios"
          :key="ratio.value"
          :class="['aspect-ratio-btn', { active: aspectRatio === ratio.value }]"
          @click="handleAspectRatioChange(ratio.value)"
        >
          <div class="ratio-icon" :style="getRatioStyle(ratio.value)"></div>
          <span>{{ ratio.label }}</span>
        </button>
      </div>

      <ColorPicker />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import ColorPicker from './components/ColorPicker.vue';
import { useColorStore } from './stores/color';

const colorStore = useColorStore();
const aspectRatio = ref('1:1');
const videoRef = ref(null);
let currentStream = null;

const aspectRatios = [
  { label: '1:1', value: '1:1' },
  { label: '3:4', value: '3:4' },
  { label: '4:3', value: '4:3' },
  { label: '3:5', value: '3:5' },
  { label: '16:9', value: '16:9' },
  { label: '9:16', value: '9:16' },
];

// 初始化摄像头
onMounted(async () => {
  initCamera(aspectRatio.value);
});

// 监听长宽比变化
watch(aspectRatio, (newRatio) => {
  initCamera(newRatio);
});

const initCamera = async (ratio) => {
  try {
    // 如果存在旧的流，先停止它
    if (currentStream) {
      currentStream.getTracks().forEach(track => track.stop());
    }

    // 根据比例计算理想的宽高
    const [width, height] = ratio.split(':').map(Number);
    const idealWidth = 1280;
    const idealHeight = Math.round(idealWidth * (height / width));

    const stream = await navigator.mediaDevices.getUserMedia({
      video: {
        width: { ideal: idealWidth },
        height: { ideal: idealHeight },
        aspectRatio: { ideal: width / height }
      }
    });

    if (videoRef.value) {
      videoRef.value.srcObject = stream;
      currentStream = stream;
    }
  } catch (err) {
    console.error('摄像头访问失败:', err);
  }
};

const getRatioStyle = (ratio) => {
  const [width, height] = ratio.split(':').map(Number);
  const baseSize = 24;
  const scale = baseSize / Math.max(width, height);
  return {
    width: `${width * scale}px`,
    height: `${height * scale}px`
  };
};

const handleAspectRatioChange = (value) => {
  aspectRatio.value = value;
};

const handleTakePhoto = () => {
  if (videoRef.value) {
    const canvas = document.createElement('canvas');
    const video = videoRef.value;
    const videoWrapper = video.parentElement;
    const wrapperAspect = videoWrapper.offsetWidth / videoWrapper.offsetHeight;

    // 使用视频的原始分辨率
    let targetWidth = video.videoWidth;
    let targetHeight = video.videoHeight;

    // 计算裁切区域，保持视窗比例但使用最大可能的分辨率
    const videoAspect = video.videoWidth / video.videoHeight;

    if (videoAspect > wrapperAspect) {
      // 视频更宽，需要裁切两边
      targetWidth = Math.round(video.videoHeight * wrapperAspect);
      targetHeight = video.videoHeight;
    } else {
      // 视频更高，需要裁切上下
      targetWidth = video.videoWidth;
      targetHeight = Math.round(video.videoWidth / wrapperAspect);
    }

    // 设置画布为目标尺寸
    canvas.width = targetWidth;
    canvas.height = targetHeight;

    const ctx = canvas.getContext('2d');

    // 计算源视频的裁切区域
    const sourceX = Math.round((video.videoWidth - targetWidth) / 2);
    const sourceY = Math.round((video.videoHeight - targetHeight) / 2);

    // 绘制时保持原始分辨率
    ctx.drawImage(
      video,
      sourceX, sourceY, targetWidth, targetHeight,  // 源区域
      0, 0, canvas.width, canvas.height             // 目标区域
    );

    const link = document.createElement('a');
    link.download = `photo-${new Date().toISOString()}.png`;
    link.href = canvas.toDataURL('image/png', 1.0);  // 使用最高质量
    link.click();
  }
};

const getVideoWrapperSize = (ratio) => {
  const [width, height] = ratio.split(':').map(Number);
  const ratio_value = width / height;
  const containerSize = 200; // 固定的容器大小

  if (ratio_value > 1) {
    // 宽大于高
    return {
      width: containerSize,
      height: containerSize / ratio_value
    };
  } else {
    // 高大于宽
    return {
      width: containerSize * ratio_value,
      height: containerSize
    };
  }
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #app {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.app-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: background-color 0.3s ease;
}

.camera-outer-container {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 2rem;
}

.camera-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.video-wrapper {
  overflow: hidden;
  border-radius: 8px;
  background: transparent;
  transition: all 0.3s ease;
}

.controls-container {
  width: 100%;
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.aspect-ratio-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  border-radius: 20px;
}

.aspect-ratio-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding: 8px;
  border: none;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s;
  opacity: 0.6;
}

.aspect-ratio-btn:hover {
  opacity: 1;
}

.aspect-ratio-btn.active {
  opacity: 1;
}

.ratio-icon {
  background: white;
  border-radius: 2px;
}

.aspect-ratio-btn span {
  font-size: 12px;
  color: white;
}

.color-picker-container {
  min-height: 200px;
  display: flex;
  justify-content: center;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: transparent;
}

.take-photo-btn {
  width: 50px;
  height: 50px;
  padding: 0;
  font-size: 24px;
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: all 0.3s;
}

.take-photo-btn:hover {
  background-color: #40a9ff;
  transform: scale(1.05);
}
</style>
