import { defineStore } from 'pinia'

export const useColorStore = defineStore('color', {
  state: () => ({
    colors: {
      hex: '#194D45'
    }
  }),
  actions: {
    updateColor(newColor) {
      this.colors.hex = newColor
    }
  }
})
