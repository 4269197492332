<template>
  <div class="color-picker-container">
    <Chrome v-model="colorStore.colors" @change="updateColor" />
  </div>
</template>

<script>
import { Chrome } from '@ckpack/vue-color'
import { useColorStore } from '../stores/color'

export default {
  name: 'ColorPicker',
  components: {
    Chrome
  },
  setup() {
    const colorStore = useColorStore()

    const updateColor = () => {
      colorStore.updateColor(colorStore.colors.hex)
    }

    return {
      colorStore,
      updateColor
    }
  }
}
</script>

<style scoped>
.color-picker-container {
  padding: 10px;
}
</style>
